let baseUrl = 'https://auth.dev.optimahr.dev'
if (document.location.hostname.endsWith('optimahr.app')) baseUrl = `https://auth.optimahr.app`
//baseUrl = 'http://localhost:8004'

// ** Auth Endpoints
export default {
  loginEndpoint: `${baseUrl}/loginv2`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${baseUrl}/token`,
  logoutEndpoint: `${baseUrl}/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
