// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialClient = () => {
  let item = window.localStorage.getItem('client')

  while (item === undefined) {
    item = window.localStorage.getItem('client')
  }

  return item ? JSON.parse(item) : undefined
}

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientData: initialClient()
  },
  reducers: {
    setClient: (state, action) => {
      state.clientData = action.payload
      localStorage.setItem('client', JSON.stringify(action.payload))
    }
  }
})

export const { setClient } = clientSlice.actions


 //function getClient(url) {
//  return url.substring(0, url.indexOf('.'))
//}

//function getEnv(url) {
//  const temp = url.replace(`${getClient(url)}.`, '')
//  return temp.substring(0, temp.indexOf('.'))
//}

export const fetchClient = () => (dispatch) => {
//  const clientFromHost = getClient(hostname)
////  const envFromHost = getEnv(hostname)
  
//  fetch(`https://auth.optimahr.app/client/images/${clientFromHost}`)
//  fetch(`http://localhost:8004/client/images/${clientFromHost}`)
//    .then((response) => response.json())
//    .then((clients) => clients[0])
//    .then((client) => {
////      dispatch(setClient({...client, url: clientFromHost}))

dispatch(setClient({name: 'Optima HR', shortname: 'Optima HR'}))
      
      initialClient()
//    })
}

export default clientSlice.reducer
