// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

import { loadVocabulary } from '@src/configs/i18n'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

async function getProfilePicture() {
    let baseUrl = 'https://auth.dev.optimahr.dev'
    if (document.location.hostname.endsWith('optimahr.app')) baseUrl = `https://auth.optimahr.app`
//    baseUrl = 'http://localhost:8004'

    await fetch(`${baseUrl}/me/profile-picture`, {
        headers: {
          Authorization: "Bearer ".concat(JSON.parse(localStorage.getItem("accessToken"))),
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => response.text()).then((data) => localStorage.setItem("avatar", data))//.then(() => window.location.reload())
}

async function getClientIdentity() {
    let baseUrl = 'https://auth.dev.optimahr.dev'
    if (document.location.hostname.endsWith('optimahr.app')) baseUrl = `https://auth.optimahr.app`
//    baseUrl = 'http://localhost:8004'
  
      await fetch(`${baseUrl}/client/identity`, {
          headers: {
            Authorization: "Bearer ".concat(JSON.parse(localStorage.getItem("accessToken"))),
            'Content-Type': 'application/x-www-form-urlencoded'
          }
      }).then((response) => response.text()).then((data) => {
//        const shortname = JSON.parse(data).shortname
//        const name = JSON.parse(data).name
//        dispatch(setClient({name, shortname}))
        localStorage.setItem("client", data)
    })//.then(() => window.location.reload())
  }
  
export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      if (action.payload.language !== "null") localStorage.setItem('i18nextLng', action.payload.language)
      loadVocabulary()
      getProfilePicture()
      getClientIdentity()
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove all from local storage
      const skin = localStorage.getItem('skin')
      let enlargedHome = localStorage.getItem('enlargedHome')
      if (enlargedHome === "null") enlargedHome = "false"
      let enlargedRepository = localStorage.getItem('enlargedRepository')
      if (enlargedRepository === "null") enlargedRepository = "false"
      let enlargedCompany = localStorage.getItem('enlargedCompany')
      if (enlargedCompany === "null") enlargedCompany = "false"
      let enlargedEmployee = localStorage.getItem('enlargedEmployee')
      if (enlargedEmployee === "null") enlargedEmployee = "false"
      let enlargedSimulation = localStorage.getItem('enlargedSimulation')
      if (enlargedSimulation === "null") enlargedSimulation = "false"
      let enlargedBudget = localStorage.getItem('enlargedBudget')
      if (enlargedBudget === "null") enlargedBudget = "false"
      const language = localStorage.getItem('i18nextLng')
      localStorage.clear()
      if (skin !== null) localStorage.setItem('skin', skin)
      localStorage.setItem('enlargedHome', enlargedHome)
      localStorage.setItem('enlargedRepository', enlargedRepository)
      localStorage.setItem('enlargedCompany', enlargedCompany)
      localStorage.setItem('enlargedEmployee', enlargedEmployee)
      localStorage.setItem('enlargedSimulation', enlargedSimulation)
      localStorage.setItem('enlargedBudget', enlargedBudget)
      if (language !== "null") localStorage.setItem('i18nextLng', language)
      sessionStorage.clear()
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
