// ** I18n Imports
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

let urlGlobal = `https://global.dev.optimahr.dev`
if (document.location.hostname.endsWith('optimahr.app')) urlGlobal = `https://global.optimahr.app`

function loadVocabulary() {
    const lang = localStorage.getItem("i18nextLng")
    const vocabularyLang = sessionStorage.getItem("language")
    if (lang !== vocabularyLang) {
        fetch(`${urlGlobal}/flatten/translation/${lang}`, {
          headers: {
           Authorization: "Bearer ".concat(JSON.parse(localStorage.getItem("accessToken"))),
           'Content-Type': 'application/x-www-form-urlencoded'
       }})
        .then((res) => res.json())
        .then((vocabulary) => {
            sessionStorage.setItem('vocabulary', JSON.stringify(vocabulary))
            sessionStorage.setItem('language', lang)
            sessionStorage.removeItem('clientvocabfr')
            sessionStorage.removeItem('clientvocaben')
        })
        .catch(function (err) {
            console.error(err)
        })
    }
}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    initImmediate: false,
    lng: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : 'fr-FR',
    interpolation: {
        escapeValue: false
    },
    backend: {
        loadPath: `${urlGlobal}/flatten/translation/{{lng}}`,
        crossDomain: true
    }
}, loadVocabulary)

import onboardingEn from './onboarding-en.json'
import onboardingFr from './onboarding-fr.json'

i18n.addResourceBundle('en-GB', 'translation', onboardingEn)
i18n.addResourceBundle('fr-FR', 'translation', onboardingFr)

export { loadVocabulary }
export default i18n
